import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { useDispatch } from "react-redux";
import { deleteItem } from "./redux/actions";
import { useStore } from "react-redux";
import { InputGroup, Form, FormControl, Button } from "react-bootstrap";
import CartFooter from "./CartFooter";
import Feature from "./util/feature";

import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import "./OrderHome.css";
import "./Cart.css";

export function fixFormat(amount) {
  return amount.toFixed(2).replace(".", ",");
}
// here the variable form OrderHome enters
function Cart({ handleCloseCart, businessId, features }) {
  const [cartChange, setCartChange] = useState(0);
  const [formData, setFormData] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const endpoint = process.env.REACT_APP_API_ORIGIN;

  const dispatch = useDispatch();
  const store = useStore();
  let totalPrice = 0;

  store.getState().items &&
    store.getState().items.forEach((item) => {
      console.log("item.options: ", item.options);
      totalPrice += item.price;
      if (item.options && item.options.extraPrice) {
        totalPrice += item.options.extraPrice;
      }
    });

  useEffect(() => {}, [cartChange]);

  function handleDeleteItem(itemId) {
    setCartChange(cartChange + 1);
    dispatch(deleteItem(itemId));
  }

  function handleChange(inputElement) {
    inputElement.preventDefault();
    setFormData({
      ...formData,
      [inputElement.target.name]: inputElement.target.value,
    });
  }

  function submit(event) {
    event.preventDefault();

    const itemsToBeSubmitted = [];
    store.getState().items.forEach((item) => {
      const itemOptions = [];
      let totalExtraPrice = 0;
      item.options &&
        Object.entries(item.options).forEach(([key, value]) => {
          let optionName;
          if (typeof value === "boolean" && value === true) {
            optionName = key;
          }
          if (typeof value === "string") {
            optionName = value;
          }
          if (key === "extraPrice") {
            totalExtraPrice = value;
          }

          optionName && itemOptions.push({ name: optionName });
        });
      console.log("ITEM OPTIONS: ", itemOptions);
      console.log("total extra price: ", item, totalExtraPrice);
      itemsToBeSubmitted.push({
        name: item.itemName,
        category: item.category,
        priceInEuros: item.price,
        totalExtraPriceInEuros: totalExtraPrice,
        item: item.id,
        quantity: item.amount,
        extras: itemOptions,
      });
    });
    const data = {
      data: {
        businessId: businessId,
        customer: {
          firstName: formData.name,
          lastName: "testnachname",
          email: "testemail@test.des",
          phoneNumber: "testphone",
          address: "testadress",
        },
        orderedItems: itemsToBeSubmitted,
        comment: formData.comment,
        totalPriceInEuros: totalPrice,
      },
    };

    axios({
      method: "post",
      url: endpoint + "/orders",
      data: data,
      headers: { "content-type": "application/json" },
    }).then(
      (response) => {
        setRedirect(true);
        console.log(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  return (
    <>
      {redirect && <Redirect to={"/menu/" + businessId + "/thanks"} />}
      <div className="Cart">
        <div className="cart-row">
          <Button
            className="submit-button"
            type="submit"
            onClick={(event) =>
              formData.name
                ? submit(event)
                : setErrorMessage("Bitte Namen angeben")
            }
          ></Button>
          <br />
          <br />
          <br />
        </div>
        <Feature name="order" features={features}>
          <div className="cart-row">Bestellübersicht</div>
        </Feature>
        <Feature name="order" toggleOff={true} features={features}>
          <div className="cart-row">Merkliste</div>
        </Feature>
        {store.getState().items &&
          store.getState().items.map((item) => (
            <>
              <div className="cart-row">
                <div className="cart-item-name cart-col">
                  <span>{item.itemName}</span>
                </div>

                <div className="cart-item-price cart-col">
                  <span>
                    {item.options
                      ? fixFormat(item.price + item.options.extraPrice)
                      : fixFormat(item.price)}
                    &nbsp;€
                  </span>
                </div>
                <div className="cart-item-delete cart-col">
                  <span
                    className="delete-icon"
                    onClick={(id) => {
                      handleDeleteItem(item.itemId);
                    }}
                  >
                    &times;
                  </span>
                </div>
              </div>
              {/* perhaps limit the number of letters, or allow 2 lines instead of custom short values?  */}
              <div className="cart-row options">
                {item.options &&
                  Object.entries(item.options).forEach(([key, value]) => {
                    if (typeof value === "boolean" && value === true) {
                      if (key === "To-Go-Becher") {
                        return <div className="cart-option">+&#8201;To-Go</div>;
                      } else if (key === "Extra Espresso Shot") {
                        return (
                          <div className="cart-option">+&#8201;Espr. Shot</div>
                        );
                      } else {
                        return <div className="cart-option">+&#8201;{key}</div>;
                      }
                    }
                    if (
                      typeof value === "string" &&
                      value !== "Normal" &&
                      value !== "Standard"
                    ) {
                      return <div className="cart-option">+&#8201;{value}</div>;
                    }
                  })}
              </div>
            </>
          ))}
        <div className="cart-row order-sum">
          <div className="cart-item-name cart-col">
            <b>
              <Feature name="order" features={features}>
                <i>Bestellsumme</i>
              </Feature>
              <Feature name="order" toggleOff={true} features={features}>
                <i>Summe</i>
              </Feature>
            </b>
          </div>
          <div className="cart-item-price cart-col">
            <b>
              <i>
                <span>{fixFormat(totalPrice)} €</span>
              </i>
            </b>
          </div>
        </div>
        <Feature name="order" features={features}>
          <div className="cart-row payment-note">
            Die Bezahlung erfolgt bei Abholung bar oder mit Karte.
          </div>
        </Feature>
      </div>
      <Feature name="order" features={features}>
        <Form className="Form">
          <div className="comment-container">
            <div>Anmerkungen zu deiner Bestellung</div>
            <div className="justify-content-center">
              <InputGroup size="sm" className="cart-input mb-3" value="">
                <FormControl
                  as="textarea"
                  type="text"
                  name="comment"
                  className="FormControl"
                  placeholder="Hier kannst du ergänzende Infos eintragen (optional) ..."
                  onChange={(e) => handleChange(e)}
                />
              </InputGroup>
            </div>
          </div>
          <required br />
          <div className="name-container">
            <div>Name für die Abholung</div>
            {errorMessage && (
              <div className="error-message">Bitte Namen angeben!</div>
            )}
            <InputGroup size="sm" className="cart-input mb-3" value="">
              <FormControl
                required={true}
                type="text"
                name="name"
                className="FormControl"
                placeholder="Name oder Alias eintragen ..."
                maxLength={16}
                onChange={(e) => handleChange(e)}
              />
            </InputGroup>
          </div>
        </Form>
        <Button className="Button" to="/" onClick={handleCloseCart}>
          <FontAwesomeIcon icon={faArrowLeft} size="1x" color="white" />
          &nbsp;&nbsp;Weitere Artikel hinzufügen&nbsp;&nbsp;
        </Button>

        <CartFooter showCart={true} />
      </Feature>
    </>
  );
}

export default Cart;
