import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

import "./OrderHome.css";
import MenuCategory from "./MenuCategory";
import CartFooter from "./CartFooter";
import Feature from "./util/feature";

function Menu(props) {
  console.log(props);
  const valuesFromStore = useSelector((state) => state.items);
  const [cartLength, setCartLength] = useState(null);

  useEffect(() => {
    const addCountToItemList = () => {
      if (valuesFromStore.length === cartLength) {
        return;
      }

      const convert = (valuesFromStore) => {
        const res = {};
        valuesFromStore.forEach((obj) => {
          const key = `${obj.id}`;
          if (!res[key]) {
            res[key] = { ...obj, count: 0 };
          }
          res[key].count += 1;
        });
        return Object.values(res);
      };

      const newItemlist = convert(valuesFromStore);
      setCartLength(newItemlist.length);
    };
    addCountToItemList();
  }, [valuesFromStore]);

  return (
    <>
      <div className="Menu">
        <div className="business-name">{props.businessName}</div>
        {/* <div className="menu-header">
          <h6>Speisen und Getränke</h6>
        </div> */}
        <Accordion className="menu-list-container ">
          {props.menuData
            .filter(({ items }) => items.length > 0)
            .map(({ category, items }, index) => (
              <MenuCategory
                key={`category-${index}`}
                categoryName={category.name}
                features={props.features}
                categoryItems={items}
              />
            ))}
        </Accordion>
      </div>
      <Feature name="order" features={props.features}>
        <CartFooter
          handleCartClick={props.handleCartClick}
          showCart={false}
          features={props.features}
        />
      </Feature>
    </>
  );
}

export default Menu;
