import "./Home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OpeningHours from "./OpeningHours";
import Footer from "./Footer";
import Feature from "./util/feature";

import {
  faUtensils,
  faCommentDots,
  faInfoCircle,
  faDoorOpen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Home({
  businessId,
  businessName,
  isOpen,
  openingTimes,
  features,
  checkinLinks,
  isAvailableMenuItem,
}) {
  const [showOpeningHours, setShowOpeningHours] = useState(false);
  useEffect(() => {}, [openingTimes]);
  const endpoint = process.env.REACT_APP_FEEDBACK;
  console.log(isAvailableMenuItem);
  if (!businessName) {
    return null;
  }

  return (
    <div className="Home">
      <div className="content-container">
        <div className="business-name">{businessName}</div>
        <div className="link-row">
          {checkinLinks.length !== 0 && (
            <Link
              className="link-container link-checkin"
              to={"/checkIn/" + businessId}
            >
              <FontAwesomeIcon
                icon={faDoorOpen}
                size="2x"
                color="rgb(0,0,0,0.7)"
              />
              Besuch anmelden
            </Link>
          )}
          <Feature name="menu" features={features}>
            <Feature name="order" features={features}>
              {isOpen ? (
                <Link
                  className="link-container link-checkin"
                  to={"/menu/" + businessId}
                >
                  <FontAwesomeIcon
                    icon={faUtensils}
                    size="2x"
                    color="rgb(191, 75, 139,0.9)"
                  />
                  Speisen und Getränke
                </Link>
              ) : (
                <div
                  className="link-container link-checkin"
                  onClick={() => setShowOpeningHours(true)}
                >
                  <FontAwesomeIcon
                    icon={faUtensils}
                    size="2x"
                    color="rgb(191, 75, 139,0.9)"
                  />
                  Speisen und Getränke
                </div>
              )}
            </Feature>
            <Feature name="order" toggleOff={true} features={features}>
              {isAvailableMenuItem && (
                <Link
                  className="link-container link-checkin"
                  to={"/menu/" + businessId}
                >
                  <FontAwesomeIcon
                    icon={faUtensils}
                    size="2x"
                    color="rgb(191, 75, 139,0.9)"
                  />
                  Speisen und Getränke
                </Link>
              )}
            </Feature>
          </Feature>

          <a
            className="link-container link-checkin"
            href={endpoint + "/survey/start/" + businessId}
          >
            <FontAwesomeIcon
              icon={faCommentDots}
              size="2x"
              color="rgb(191, 75, 139,0.9)"
            />
            Feedback senden
          </a>
          <Link
            className="link-container link-checkin"
            to={"/information/" + businessId}
          >
            <FontAwesomeIcon
              icon={faInfoCircle}
              size="2x"
              color="rgb(191, 75, 139,0.9)"
            />
            Über uns
          </Link>
        </div>

        {showOpeningHours && (
          <div className="notice-closed">
            {" "}
            Bestellungen sind nur während der Öffnungszeiten möglich:
          </div>
        )}
        {showOpeningHours && <OpeningHours openingTimes={openingTimes} />}
      </div>
      <Footer />
    </div>
  );
}

export default Home;
