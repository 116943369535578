import { useEffect } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faArrowRight,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { fixFormat } from "./Cart";
import { Navbar } from "react-bootstrap";
import Feature from "./util/feature";

import "./OrderHome.css";
import "./CartFooter.css";

function CartFooter(props) {
  useEffect(() => {}, [props]);

  if (props.number !== 0) {
    return (
      <Navbar bg="light" variant="light">
        <nav className="cart">
          <div
            className="nav-link"
            to="/cart"
            onClick={props.showCart ? props.submit : props.handleCartClick}
          >
            <div className="header-cart-info">
              {!props.showCart && (
                <>
                  <div className="cartIcon">
                    <div>
                      <Feature name="order" features={props.features}>
                        <FontAwesomeIcon
                          icon={faShoppingCart}
                          color="#fafafa"
                        />
                      </Feature>

                      <Feature
                        name="order"
                        toggleOff={true}
                        features={props.features}
                      >
                        <FontAwesomeIcon
                          icon={faClipboardList}
                          color="#fafafa"
                        />
                      </Feature>
                    </div>
                  </div>
                  <div className="header-cart-amount">
                    {props.number === 1 && `${props.number} Produkt  |`}&nbsp;
                    {props.number > 1 && `${props.number} Produkte  |`}&nbsp;
                  </div>
                  <div className="header-cart-price">
                    <b>
                      {props.totalPrice > 0 &&
                        "€ " + fixFormat(props.totalPrice)}
                    </b>
                  </div>
                </>
              )}
              {props.showCart && (
                <>
                  <div className="header-cart-content">
                    <b>Bestellung absenden&nbsp;</b>
                  </div>
                  <div className="header-cart-price">
                    {props.totalPrice > 0 &&
                      "(€ " + fixFormat(props.totalPrice) + ")"}
                  </div>
                </>
              )}

              <div className="header-cart-arrow">
                <FontAwesomeIcon icon={faArrowRight} size="1x" color="white" />
              </div>
            </div>
          </div>
        </nav>
      </Navbar>
    );
  } else {
    return null;
  }
}

function mapStateToProps(state) {
  let totalPrice = 0;
  state.items.map((item) => {
    console.log("item in cartfooter: ", item);
    totalPrice += item.price;
    if (item.options && item.options.extraPrice) {
      totalPrice += item.options.extraPrice;
    }
  });
  return {
    number: state.items.length,
    totalPrice: totalPrice,
  };
}

export default connect(mapStateToProps)(CartFooter);
