import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from "./redux/actions";
import { connect } from "react-redux";
import { fixFormat } from "./Cart";
import OptionsModal from "./OptionsModal";
import Feature from "./util/feature";

import "./MenuItem.css";
import { Card } from "react-bootstrap";

// here orderFeature active/false
function MenuItem({ menuItem, features }) {
  const { _id, name, description, priceInEuros, category } = menuItem;
  const [currentAmount, setCurrentAmount] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [itemId, setItemId] = useState(null);

  const valuesFromStore = useSelector((state) => state.items);

  function handleOptionsSubmit(optionsState) {
    handleCartChange(null, null, optionsState);
    setShowModal(false);
  }

  function addItemToStore(optionsState) {
    console.log(" optionsState", optionsState);
    dispatch(
      addItem(_id, name, 1, priceInEuros, optionsState, itemId, category)
    );
    setItemId(Math.floor(Math.random() * 1000000));
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  function handleCartChange(event, increment, optionsState) {
    console.log("currentAmount: ", currentAmount);

    let extras = menuItem.extras.length > 0;
    let newValue;
    if (!extras) {
      if (currentAmount === null) {
        newValue = 1;
      } else {
        newValue = currentAmount + 1;
      }
      setCurrentAmount(newValue);
      addItemToStore(null);
    }
    if (extras && showModal === false) {
      setShowModal(true);
    } else if (extras && showModal === true) {
      if (currentAmount === null) {
        newValue = 1;
        addItemToStore(optionsState);
      } else {
        newValue = currentAmount + 1;
        addItemToStore(optionsState);
      }
      setCurrentAmount(newValue);
      setShowModal(false);
    }
  }

  useEffect(() => {
    setItemId(Math.floor(Math.random() * 1000000));
  }, []);

  useEffect(() => {
    let itemValues = valuesFromStore.filter((item) => item.id === _id);
    itemValues && setCurrentAmount(itemValues.length);
  }, [currentAmount]);

  const dispatch = useDispatch();

  return (
    <>
      {showModal && (
        <OptionsModal
          handleCloseModal={handleCloseModal}
          menuItem={menuItem}
          handleOptionsSubmit={handleOptionsSubmit}
        />
      )}
      <Card className="MenuItem" id={_id}>
        <div className="item-row">
          <div className="item-name ">{name}</div>
        </div>
        <div className="item-row">
          <div className="description">{description}</div>
        </div>
        <div className="item-row">
          <div className="item-price">€ {fixFormat(priceInEuros)}</div>
          <div className="choose-amount ">
            {currentAmount > 0 && <div className="amount">{currentAmount}</div>}
            <Feature name="order" features={features}>
              <div
                onClick={(e) => handleCartChange(null, 1)}
                className="icon-plus-minus"
              >
                +
              </div>
            </Feature>
          </div>
        </div>
      </Card>
    </>
  );
}

export default connect(null, { addItem })(MenuItem);
