import "./OrderHome.css";
import "bootstrap/dist/css/bootstrap.min.css";
import OrderHome from "./OrderHome";
import Home from "./Home";
import Checkin from "./Checkin";
import Information from "./Information";
import { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import axios from "axios";
import Navigation from "./components/navbar/Navigation";
import { getBackground } from "./util/imageKit";
import Helmet from "react-helmet";

var moment = require("moment");

function App({ match }) {
  const [businessName, setBusinessName] = useState();
  const [businessType, setBusinessType] = useState("default");
  const [background, setBackground] = useState();
  const [openingTimes, setOpeningTimes] = useState();
  const [information, setInformation] = useState({});
  const [checkinLinks, setCheckinLinks] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const [contactLinks, setContactLinks] = useState([]);
  const [links, setLinks] = useState([]);
  const [features, setFeatures] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [menuData, setMenuData] = useState();
  const { businessId } = match.params;
  const [isAvailableMenuItem, setIsAvailableMenuItem] = useState(Boolean);
  const endpoint = process.env.REACT_APP_API_ORIGIN;

  function checkIfOpen() {
    let currentDay = moment().format("dddd");
    let currentDayFormatted =
      currentDay.charAt(0).toLowerCase() + currentDay.slice(1);
    let openingHoursToday = openingTimes.filter(
      (day) => day.period === currentDayFormatted
    );
    if (openingHoursToday.length > 0) {
      let todayOpenStart = openingHoursToday[0].from.replace(":", "");
      let todayOpenEnd = openingHoursToday[0].to.replace(":", "");
      let currentTime = moment().format("HHmm");
      setIsOpen(currentTime >= todayOpenStart && currentTime <= todayOpenEnd);
    } else {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    getBusinessData();
    getBusinessType();
  }, [businessId]);

  useEffect(() => {
    setBackground(getBackground(businessType));
  }, [businessType]);

  async function getBusinessData() {
    (async () => {
      try {
        axios({
          method: "post",
          url: endpoint + "/business/visits/" + businessId + "/home",
          headers: { "content-type": "application/json" },
        });
        const res = await axios.get(endpoint + "/business/" + businessId);
        const reslinks = await axios.get(
          endpoint + "/business/links/" + businessId
        );
        const menu = await axios.get(
          endpoint + "/menu/available/" + businessId
        );

        let isAvailableMenuItem = menu.data?.data?.items[0]?.items[0]
          ? true
          : false;

        isAvailableMenuItem && setIsAvailableMenuItem(isAvailableMenuItem);
        let links = reslinks.data.data;
        links && setLinks(links);
        let businessData = res.data.data[0];
        businessData && setOpeningTimes(businessData.openingTimes);
        businessData && setFeatures(businessData.features);
        businessData && setInformation(businessData.information);
        businessData && setBusinessName(businessData.businessName);
        console.log(links);
      } catch (err) {
        console.error(err);
      }
    })();
  }

  async function getBusinessType() {
    if (businessId) {
      try {
        const res = await axios.get(
          endpoint + "/business/getBusinessType/" + businessId
        );
        setBusinessType(res.data.data);
      } catch (err) {
        console.error(err);
      }
    }
  }

  useEffect(() => {
    getMenu();
  }, [businessId]);

  async function getMenu() {
    if (businessId) {
      try {
        const res = await axios.get(endpoint + "/menu/available/" + businessId);
        setMenuData(res?.data?.data?.items);
      } catch (err) {
        console.error(err);
      }
    }
  }

  useEffect(() => {
    let checkin = links.filter((link) => link.type === "checkin");
    setCheckinLinks(checkin);
    let social = links.filter((link) => link.type === "social");
    setSocialLinks(social);
    let contact = links.filter((link) => link.type === "contact");
    setContactLinks(contact);
    console.log("checkin: ", checkin, "social: ", social);
  }, [links]);

  useEffect(() => {
    openingTimes && checkIfOpen();
  }, [openingTimes]);

  useEffect(() => {}, [isOpen]);

  return (
    <div>
      <Helmet bodyAttributes={{ style: background }} />
      <BrowserRouter>
        <Navigation
          features={features}
          businessId={businessId}
          isAvailableMenuItem={isAvailableMenuItem}
        />
        <Switch>
          <Route
            exact
            path="/menu/:businessId"
            component={() => (
              <OrderHome
                businessId={businessId}
                businessName={businessName}
                features={features}
                menuData={menuData}
              />
            )}
          />

          <Route
            exact
            path="/:businessId"
            component={() => (
              <Home
                businessId={businessId}
                businessName={businessName}
                isOpen={isOpen}
                openingTimes={openingTimes}
                features={features}
                checkinLinks={checkinLinks}
                isAvailableMenuItem={isAvailableMenuItem}
              />
            )}
          />
          <Route
            exact
            path="/checkIn/:businessId"
            component={() => (
              <Checkin businessId={businessId} checkinLinks={checkinLinks} />
            )}
          />
          <Route
            exact
            path="/information/:businessId"
            component={() => (
              <Information
                businessName={businessName}
                information={information}
                socialLinks={socialLinks}
                contactLinks={contactLinks}
                openingTimes={openingTimes}
              />
            )}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
