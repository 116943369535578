const ADD_ITEM = "ADD_ITEM";
const DELETE_ITEM = "DELETE_ITEM";

export const addItem = (id, itemName, amount, price, options, itemId, category) => ({
  type: ADD_ITEM,
  payload: {
    id,
    itemName,
    amount,
    price,
    options,
    itemId,
    category
  },
});

export const deleteItem = (itemId) => ({
  type: DELETE_ITEM,
  itemId
});
