export default function reducer(state = { items: [] }, action) {
  if (action.type === "ADD_ITEM") {
    state = {
      ...state,
      items: [...state.items, action.payload],
    };
  }

  if (action.type === "DELETE_ITEM") {
    console.log(state.items.map(item => console.log("reducer itemId to reduce: ", item.itemId)
    ));
    console.log("reducer action itemId: ", action.itemId);


    state = {
      ...state,
      items: state.items.filter(
        (item) => item.itemId !== action.itemId
      ),
    };
  }

return state;
}
