import "./SocialLinks.css";

function SocialLinks({ socialLinks }) {
  console.log("socialLinks", socialLinks);
  if (socialLinks && socialLinks.length > 0) {
    return (
      <div className="Social-links">
        {socialLinks.map((link) => (
          <a
            className="link"
            id={link.linkId}
            href={link.url}
            target="_blank"
            rel="noreferrer"
            key={link.linkId}
          >
            <i className={`${link.icon} icon`} />
          </a>
        ))}
      </div>
    );
  } else {
    return null;
  }
}

export default SocialLinks;
