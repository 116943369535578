import "./OrderHome.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Menu from "./Menu";
import Cart from "./Cart";
import Thanks from "./Thanks";
import Footer from "./Footer";
import axios from "axios";
import { useState, useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";

// obene function gets property from parent component ()
function OrderHome({ businessId, businessName, features, menuData }) {
  const [showCart, setShowCart] = useState(false);
  // const [menuData, setMenuData] = useState();
  const endpoint = process.env.REACT_APP_API_ORIGIN;

  // useEffect(() => {
  //   getMenu();
  // }, [businessId]);

  // async function getMenu() {
  //   if (businessId) {
  //     try {
  //       const res = await axios.get(endpoint + "/menu/available/" + businessId);
  //       setMenuData(res?.data?.data?.items);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   }
  // }

  useEffect(() => {}, [showCart]);

  function handleCartClick() {
    setShowCart(true);
    window.scrollTo({ top: 0 });
  }

  function handleCloseCart() {
    setShowCart(false);
  }

  if (!menuData) {
    return null;
  }

  return (
    <div>
      <BrowserRouter>
        <div className="OrderHome">
          <div className="content-container">
            <div className="content">
              {showCart ? (
                <Route
                  exact
                  path="/menu/:businessId"
                  component={() => (
                    <Cart
                      handleCloseCart={handleCloseCart}
                      businessId={businessId}
                      features={features}
                    />
                  )}
                />
              ) : (
                <Route
                  exact
                  path="/menu/:businessId"
                  component={() => (
                    <Menu
                      menuData={menuData}
                      handleCartClick={handleCartClick}
                      businessId={businessId}
                      features={features}
                      businessName={businessName}
                    />
                  )}
                />
              )}
              <Route
                exact
                path="/menu/:businessId/thanks"
                component={() => <Thanks businessName={businessName} />}
              />

              <Footer />
            </div>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default OrderHome;
