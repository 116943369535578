import "./OpeningHours.css";

function OpeningHours({ openingTimes }) {
  const daysEnglish = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const daysDeutsch = [
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
    "Sonntag",
  ];

  function convertDayToGerman(dayEnglish) {
    let index = daysEnglish.findIndex((day) => day === dayEnglish);
    return daysDeutsch[index];
  }

  return (
    <div className="opening-hours-container">
      {openingTimes &&
        openingTimes.map((day) => (
          <div key={day._id}>
            <div className="opening-hours-day">
              {convertDayToGerman(day.period)}:
            </div>
            <div>
              {day.from} - {day.to} Uhr
            </div>
          </div>
        ))}
    </div>
  );
}

export default OpeningHours;
