import "./Information.css";
import Footer from "./Footer";
import SocialLinks from "./SocialLinks";
import ContactLinks from "./ContactLinks";
import OpeningHours from "./OpeningHours";
import React from "react";
import ReactHtmlParser from "react-html-parser";

function Information({
  businessName,
  information,
  socialLinks,
  contactLinks,
  openingTimes,
}) {
  console.log(contactLinks);
  return (
    <div className="Information">
      <div className="content-container">
        <div className="business-name ">{businessName}</div>
        <div className="link-row">
          {information.infoHeadline && (
            <div className="infoHeadline">
              <div>{information.infoHeadline}</div>
              <hr></hr>
            </div>
          )}

          {information.infoText && (
            <div className="infoText display-linebreak">
              <div>{information.infoText}</div>
              <div>
                <br />
              </div>
            </div>
          )}

          {openingTimes && openingTimes.length !== 0 && (
            <div>
              <div className="infoHeadline">Unsere Öffnungszeiten:</div>
              <hr></hr>
              <OpeningHours openingTimes={openingTimes} />
            </div>
          )}
          {socialLinks && socialLinks.length !== 0 && (
            <div>
              <div className="infoHeadline">Links:</div>
              <hr></hr>
              <SocialLinks socialLinks={socialLinks} />
              <div>
                <br />
              </div>
            </div>
          )}

          {contactLinks && contactLinks.length !== 0 && (
            <div>
              <div className="infoHeadline">Kontakt:</div>
              <hr></hr>
              <ContactLinks contactLinks={contactLinks} />
              <div>
                <br />
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Information;
