import { useState, useEffect } from "react";
import { Form, FormControl, Button } from "react-bootstrap";
import "./OptionsModal.css";
import { fixFormat } from "./Cart";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";

function Extra({
  extra,
  handleOptionsSubmit,
  handleChangeBool,
  handleChangeOptions,
  state,
}) {
  const [localState, setLocalState] = useState({});
  const [localExtraName, setLocalExtraName] = useState([]);

  //activated / not activated
  useEffect(() => {
    setLocalState(state);
  }, [state]);

  useEffect(() => {
    setLocalExtraName(extra.name);
  }, [localState]);

  useEffect(() => {
    console.log("localExtraName: ", localExtraName);
  }, [localExtraName]);

  //if extra contains options, shows the options
  return extra.options.length ? (
    <fieldset
      className="options-modal-radio"
      onChange={(e) => handleChangeOptions(extra.name, e, extra)}
    >
      <div className="options-modal-row options-modal-titel">
        <p>{extra.name}</p>
      </div>
      {extra.options.map((option) => (
        <div className="options-modal-row">
          <Radio
            type="radio"
            name={extra.name}
            value={option.name}
            id={extra.name + option.name}
            defaultChecked={option.default}
          />

          <label for={extra.name + option.name}>{option.name}</label>
          <span className="price-difference">
            {option.priceInEuros && (
              <span>
                + {option.priceInEuros && fixFormat(option.priceInEuros)} €
              </span>
            )}
          </span>
        </div>
      ))}
    </fieldset>
  ) : (
    <fieldset className="options-modal-checkbox">
      <div className="options-modal-row">
        {/*  I added extra.name as an id to make label clickable*/}
        <Checkbox
          type="checkbox"
          id={extra.name}
          name={extra.name}
          value={
            state["{extra.name}"] &&
            state["{extra.name}"].event &&
            state["{extra.name}"].event.target.value
          }
          defaultChecked={extra.default}
          onChange={(e) => {
            handleChangeBool(e, extra.priceInEuros);
          }}
        />
        {/* why is label size-s, is that a fix value? */}
        <label for={extra.name}>{extra.name}</label>
        <span className="price-difference">
          {extra.priceInEuros && (
            <span className="price-difference">
              + {extra.priceInEuros && fixFormat(extra.priceInEuros)} €
            </span>
          )}
        </span>
      </div>
    </fieldset>
  );
}

function OptionsModal({ handleCloseModal, menuItem, handleOptionsSubmit }) {
  // Here we shold find a way to get default values from the DB

  const [state, setState] = useState({});
  const [totalExtraPrice, setTotalExtraPrice] = useState(0);
  const [totalOptionsPrice, setTotalOptionsPrice] = useState(0);
  const [totalExtraOptionsPrice, setTotalExtraOptionsPrice] = useState(0);
  const extras = menuItem.extras;

  useEffect(() => {
    console.log("extras", extras);
    const extrasDefault = {};

    extras.length === 0
      ? setState({})
      : extras.forEach((extra) => {
          let extraName = extra.name;
          if (extra.default) {
            extrasDefault[extraName] = true;
          }
          extra.options.forEach((option) => {
            if (option.default) {
              extrasDefault[extraName] = option.name;
            }
          });
          setState(extrasDefault);
        });
  }, []);

  useEffect(() => {
    let priceOfExtra;
    let totalExtras = 0;
    extras.length === 0
      ? setTotalExtraOptionsPrice(0)
      : extras.forEach((extra) => {
          if (extra.priceInEuros) {
            priceOfExtra = state[extra.name] ? extra.priceInEuros : 0;
            totalExtras += priceOfExtra;
            console.log(
              "state of: " +
                extra.name +
                " " +
                state[extra.name] +
                " " +
                priceOfExtra
            );
          }
          console.log("total extra price: " + totalExtras);
          setTotalExtraPrice(totalExtras);
        });

    let priceOfOption;
    let totalOptions = 0;

    extras.forEach((extra) => {
      if (!extra.priceInEuros) {
        extra.options.forEach((option) => {
          if (state[extra.name] === option.name) {
            priceOfOption = option.priceInEuros ? option.priceInEuros : 0;
            totalOptions += priceOfOption;
            console.log("option : " + extra.name + " price : " + priceOfOption);
          }
        });
      }
      console.log("total options price: " + totalOptions);
      setTotalOptionsPrice(totalOptions);
    });
    console.log("state: ", state);
  }, [state]);

  useEffect(() => {
    console.log("statetotalExtraPrice: ", totalExtraPrice);
    setTotalExtraOptionsPrice(totalExtraPrice + totalOptionsPrice);
  }, [totalExtraPrice]);

  useEffect(() => {
    console.log("state totalOptionsPrice: ", totalOptionsPrice);
    setTotalExtraOptionsPrice(totalExtraPrice + totalOptionsPrice);
  }, [totalOptionsPrice]);

  useEffect(() => {
    console.log("state totalExtraOptionsPrice : ", totalExtraOptionsPrice);
  }, [totalExtraOptionsPrice]);

  useEffect(() => {
    console.log("totalExtraOptionsPrice: ", totalExtraOptionsPrice);
    setState((state) => ({
      ...state,
      extraPrice: totalExtraOptionsPrice,
    }));
  }, [totalExtraOptionsPrice]);

  function handleChangeBool(event, extraPrice) {
    const {
      target: { name, value },
    } = event;

    setState((state) => ({
      ...state,
      [name]: !state[name],
      extraPrice: totalExtraOptionsPrice,
    }));
  }

  function handleChangeOptions(extraName, event, extra) {
    setState((state) => ({
      ...state,
      [extraName]: event.target.value,
      extraPrice: totalExtraOptionsPrice,
    }));
  }

  return (
    <div className="modal">
      <div className="modal-main">
        <div className="options-modal-header">
          <div className="delete-icon" onClick={handleCloseModal}>
            &times;
          </div>
          <div>{menuItem.name} - Optionen</div>
        </div>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleOptionsSubmit(state);
          }}
        >
          {extras.map((extra) => {
            return (
              <Extra
                extra={extra}
                handleOptionsSubmit={handleOptionsSubmit}
                handleChangeBool={handleChangeBool}
                handleChangeOptions={handleChangeOptions}
                state={state}
              />
            );
          })}
          <Button className="Button" type="submit">
            &nbsp;&nbsp;Zur Bestellung hinzufügen&nbsp;&nbsp;
            <FontAwesomeIcon icon={faArrowRight} size="1x" color="white" />
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default OptionsModal;
