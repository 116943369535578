import React from "react";
import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUtensils,
  faCommentDots,
  faHome,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import Feature from "../../util/feature";
import "./Navigation.css";

const Navbar = ({ features, businessId, isAvailableMenuItem }) => {
  const endpoint = process.env.REACT_APP_FEEDBACK;
  let tabs = [
    {
      route: `/information/${businessId}`,
      icon: faInfoCircle,
      label: "Info",
    },
    {
      href: endpoint + "/survey/start/" + businessId,
      icon: faCommentDots,
      label: "Feedback",
    },
    {
      route: `/menu/${businessId}`,
      icon: faUtensils,
      label: "Karte",
    },
    {
      route: `/${businessId}`,
      icon: faHome,
      label: "Home",
    },
  ];
  if (!isAvailableMenuItem) {
    tabs = tabs.filter(function (ele) {
      return ele.label != "Karte";
    });
  }
  return (
    <div>
      <nav
        className="navbar navigation fixed-bottom navbar-light bottom-tab-nav"
        role="navigation"
      >
        <Nav className="w-100">
          <div className=" d-flex flex-row justify-content-around w-100">
            {tabs.map((tab, index) => (
              <NavItem key={`tab-${index}`}>
                {tab.label === "Feedback" ? (
                  <a
                    className="nav-link bottom-nav-link"
                    href={endpoint + "/survey/start/" + businessId}
                  >
                    <div className="row d-flex flex-column justify-content-center align-items-center">
                      <FontAwesomeIcon size="lg" icon={tab.icon} />
                      <div className="bottom-tab-label">{tab.label}</div>
                    </div>
                  </a>
                ) : (
                  <NavLink
                    to={tab.route}
                    className="nav-link bottom-nav-link"
                    activeClassName="active"
                  >
                    <div className="row d-flex flex-column justify-content-center align-items-center">
                      <FontAwesomeIcon size="lg" icon={tab.icon} />
                      <div className="bottom-tab-label">{tab.label}</div>
                    </div>
                  </NavLink>
                )}
              </NavItem>
            ))}
          </div>
        </Nav>
      </nav>
    </div>
  );
};
export default Navbar;

// import React, { useState } from "react";
// import { NavLink as RouterNavLink } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Feature from "../../util/feature";
// // import "./Navbar.css";
// import {
//   faUtensils,
//   faCommentDots,
//   faHome,
//   faDoorOpen,
// } from "@fortawesome/free-solid-svg-icons";

// import { Container, Navbar, Nav, NavItem, NavLink } from "reactstrap";

// const NavBar = ({ features, businessId }) => {
//   const endpoint = process.env.REACT_APP_API_ORIGIN;
//   return (
//     <div className="nav-container ">
//       <Container>
//         <Navbar light expand="md">
//           <Nav className="ml-auto justify-content-center">
//             {/* <NavItem>
//               <a
//                 className="link"
//                 href={endpoint + "/c/" + businessId}
//                 target="_top"
//                 rel="noreferrer"
//               >
//                 <FontAwesomeIcon icon={faCommentDots} size="1x" color="white" />
//                 Feedback
//               </a>
//             </NavItem> */}

//             <Nav.Item>
//               <Nav.Link
//                 tag={RouterNavLink}
//                 to={"/" + businessId}
//                 exact
//                 activeClassName="router-link-exact-active"
//               >
//                 <FontAwesomeIcon icon={faHome} size="1x" color="white" />
//                 Home
//               </Nav.Link>
//             </Nav.Item>

//             {/* <Feature name="menu" features={features}> */}
//             <Nav.Item>
//               <Nav.Link
//                 tag={RouterNavLink}
//                 to={"/menu/" + businessId}
//                 exact
//                 activeClassName="router-link-exact-active"
//               >
//                 <FontAwesomeIcon icon={faUtensils} size="1x" color="white" />
//                 Karte
//               </Nav.Link>
//             </Nav.Item>
//             {/* </Feature> */}
//           </Nav>
//         </Navbar>
//       </Container>
//     </div>
//   );
// };

// export default NavBar;
