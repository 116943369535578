import "./ContactLinks.css";

function ContactLinks({ contactLinks }) {
  if (contactLinks && contactLinks.length > 0) {
    return (
      <div className="Contact-links">
        {contactLinks.map((link) =>
          link.linkId === "email" ? (
            <a
              className="link"
              id={link.linkId}
              href={`mailto:${link.url}`}
              target="_blank"
              rel="noreferrer"
              key={link.linkId}
            >
              <i className={`${link.icon} icon`} />
            </a>
          ) : (
            <a
              className="link"
              id={link.linkId}
              href={`tel:${link.url}`}
              target="_blank"
              rel="noreferrer"
              key={link.linkId}
            >
              <i className={`${link.icon} icon`} />
            </a>
          )
        )}
      </div>
    );
  } else {
    return null;
  }
}

export default ContactLinks;
