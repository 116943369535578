import "./Footer.css";
import logo from "./img/logo.png";

function Footer() {
  return (
    <footer>
      <img className="logo" alt="myFeedoo" src={logo} />
      <div className="footer-links">
        <a
          href="https://www.feedoo.app/impressum"
          target="_blank"
          className="Link"
        >
          Impressum
        </a>
        <a href="https://www.feedoo.app/gdpr" target="_blank" className="Link">
          Datenschutz
        </a>
      </div>
    </footer>
  );
}

export default Footer;
