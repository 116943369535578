import "./OrderHome.css";
import "./Thanks.css";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function Thanks({ businessName }) {
  useEffect(() => {}, [businessName]);

  return (
    <>
      <div className="Thanks">
        <div className="check-icon">
          <FontAwesomeIcon icon={faCheck} size="4x" color="white" />
        </div>

        <h2>VIELEN DANK</h2>
        <div>
          {businessName} bedankt sich für Deine Bestellung! Sobald alles fertig
          ist, rufen wir Deinen Namen auf.
        </div>
      </div>
    </>
  );
}

export default Thanks;
