import "./Home.css";
import { Link } from "react-router-dom";
import Footer from "./Footer";


function Checkin({ businessId, checkinLinks }) {


  return (
    <div className="Checkin">
      <div className="content-container">
        <div className="checkin-title">
          Bitte hier eine App für die Anmeldung auswählen
        </div>
        <div className="link-row">
          {checkinLinks &&
            checkinLinks.map((app) => (

              <a
                className="link-container link-checkin"
                href={app.url}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="logo-corona-app"
                  alt={app.name + "_logo"}
                  src={`/${app.linkId}.png`}
                />
                {app.name}
              </a>
            ))}
          <Link className="link-container link-checkin" to={"/" + businessId}>
            Nein, danke! Ich nutze lieber das Formular auf Papier.
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Checkin;
