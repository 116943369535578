import "./MenuItem.css";

import MenuItem from "./MenuItem";

function MenuCategory({ categoryName, categoryItems, features }) {
  return (
    <div className="MenuCategory">
      <h3 className="category-name">{categoryName}</h3>
      {categoryItems.map((item, index) => (
        <MenuItem
          key={`menu-item-${index}`}
          menuItem={item}
          features={features}
        />
      ))}
    </div>
  );
}

export default MenuCategory;
